@import "../affix/style/index-pure.less";
@import "../alert/style/index-pure.less";
@import "../anchor/style/index-pure.less";
@import "../auto-complete/style/index-pure.less";
@import "../avatar/style/index-pure.less";
@import "../back-top/style/index-pure.less";
@import "../badge/style/index-pure.less";
@import "../breadcrumb/style/index-pure.less";
@import "../button/style/index-pure.less";
@import "../calendar/style/index-pure.less";
@import "../card/style/index-pure.less";
@import "../carousel/style/index-pure.less";
@import "../cascader/style/index-pure.less";
@import "../checkbox/style/index-pure.less";
@import "../collapse/style/index-pure.less";
@import "../comment/style/index-pure.less";
@import "../config-provider/style/index-pure.less";
@import "../date-picker/style/index-pure.less";
@import "../descriptions/style/index-pure.less";
@import "../divider/style/index-pure.less";
@import "../drawer/style/index-pure.less";
@import "../dropdown/style/index-pure.less";
@import "../empty/style/index-pure.less";
@import "../form/style/index-pure.less";
@import "../grid/style/index-pure.less";
@import "../icon/style/index-pure.less";
@import "../image/style/index-pure.less";
@import "../input/style/index-pure.less";
@import "../input-number/style/index-pure.less";
@import "../layout/style/index-pure.less";
@import "../list/style/index-pure.less";
@import "../locale-provider/style/index-pure.less";
@import "../mentions/style/index-pure.less";
@import "../menu/style/index-pure.less";
@import "../message/style/index-pure.less";
@import "../modal/style/index-pure.less";
@import "../notification/style/index-pure.less";
@import "../page-header/style/index-pure.less";
@import "../pagination/style/index-pure.less";
@import "../popconfirm/style/index-pure.less";
@import "../popover/style/index-pure.less";
@import "../progress/style/index-pure.less";
@import "../radio/style/index-pure.less";
@import "../rate/style/index-pure.less";
@import "../result/style/index-pure.less";
@import "../segmented/style/index-pure.less";
@import "../select/style/index-pure.less";
@import "../skeleton/style/index-pure.less";
@import "../slider/style/index-pure.less";
@import "../space/style/index-pure.less";
@import "../spin/style/index-pure.less";
@import "../statistic/style/index-pure.less";
@import "../steps/style/index-pure.less";
@import "../switch/style/index-pure.less";
@import "../table/style/index-pure.less";
@import "../tabs/style/index-pure.less";
@import "../tag/style/index-pure.less";
@import "../time-picker/style/index-pure.less";
@import "../timeline/style/index-pure.less";
@import "../tooltip/style/index-pure.less";
@import "../transfer/style/index-pure.less";
@import "../tree/style/index-pure.less";
@import "../tree-select/style/index-pure.less";
@import "../typography/style/index-pure.less";
@import "../upload/style/index-pure.less";
