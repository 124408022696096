.sider {
  overflow: 'auto';
  height: '100vh';
  position: 'fixed'
}

.ant-layout {
  min-height: 100vh;
}

.ant-layout-footer {
  text-align: center;
}

.lazy-loading {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
