.dark {
  @import 'antd/dist/antd.dark.less';

  @body-background: #121620;
  @text-color: #eeeeee;
  @input-placeholder-color: #bbbbbb;
  @table-row-hover-bg: #242b32;

  color: @text-color;

  .ant-layout-sider {
    background: #101018;
  }
  
  .ant-menu {
    background: #101018;
  }
  
  .ant-alert-with-description .ant-alert-description {
    display: block;
  }

  .comp-list-item:hover li {
    background-color: @table-row-hover-bg;
    color: @text-color;
  }

  .ant-typography-success {
    color: @success-color;
  }
}
